import { environment } from "src/variables";
// Components
import Header from "src/app/page/Header";
import Form from "src/app/page/Form";

import locations from "src/app/assets/service_locations.jpg";
import backgroundImage from "src/app/assets/background.jpg";
import CallIcon from "@material-ui/icons/Call";
import {
  createTheme,
  ThemeProvider,
  Typography,
  Card,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

// Styles
import { Wrapper, Spacer } from "src/app/App.styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1ba0d7",
    },
    secondary: {
      main: "#a27c10",
    },
  },
});

const bannerText: any = {
  style: {
    fontFamily: "Georgia, serif",
    background: "linear-gradient(#1ba0d7, #a27c10)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "50px",
  },
};

const headerText: any = {
  style: {
    fontFamily: "Georgia, serif",
    fontSize: "36px",
  },
};

const floatLeft: any = {
  style: {
    textAlign: "start",
    minWidth: "715px",
    fontFamily: "Georgia, serif",
    fontSize: "36px",
  },
};

const rules: string =
  "No hazardous material, alcohol, or tobacco. " +
  "Items may not exceed 3 feet in length. " +
  "We do not handle any portion of the sale, please ensure the items are available for pickup.";

const cites: any = [
  { value: "Apple Valley", state: "UT", zip: 84737 },
  { value: "Cane Beds", state: "AZ", zip: 86021 },
  { value: "Centennial Park", state: "AZ", zip: 86021 },
  { value: "Colorado City", state: "AZ", zip: 86021 },
  { value: "Hildale", state: "UT", zip: 84784 },
  { value: "Hurricane", state: "UT", zip: 84737 },
  { value: "St George", state: "UT", zip: "Please enter you zip in the Special instructions below" },
  { value: "Toqorville", state: "UT", zip: 84774 },
  { value: "Kanab", state: "UT", zip: 84741 },
  { value: "Washington", state: "UT", zip: 84780 },
];

const pickup: any = {
  type: "sub-form",
  label: "Place of pickup",
  action: {
    key: "pickup_add",
    label: "Add",
    type: "button",
  },
  address: { label: "Address", type: "text", required: true },
  city: { label: "City", type: "select", options: cites, required: true },
  state: { label: "State", type: "label", path: ["pickup", "city", "value", "state"] },
  zip: { label: "Zip", type: "label", path: ["pickup", "city", "value", "zip"] },
};

const drop: any = {
  type: "sub-form",
  label: "Place of drop-off",
  action: {
    key: "drop-off_add",
    label: "Add",
    type: "button",
  },
  name: { type: "hidden" },
  phone: { type: "hidden" },
  address: { label: "Address", type: "text", required: true },
  city: { label: "City", type: "select", options: cites, required: true },
  state: { label: "State", type: "label", path: ["drop", "city", "value", "state"] },
  zip: { label: "Zip", type: "label", path: ["drop", "city", "value", "zip"] },
};

const company: any = {
  fields: {
    type: "overrides",
    "+1 (435) 429-3899": {
      name: "Dev Mathew Berg",
      email: "cybeffects@gmail.com",
      pickup: {
        address: "",
        city: cites,
        state: pickup.state,
        zip: pickup.zip,
      },
      drop: {
        address: "2425 S 2100 E",
        city: cites[1],
        state: "AZ",
        zip: 86021,
      },
    },
    "+1 (435) 215-2980": {
      label: "Business name",
      name: "Red Brick Take n Bake Pizza",
      pickup: {
        address: "385 Township Ave",
        city: cites[3],
        state: "AZ",
        zip: 86021,
      },
      drop: {
        name: { id: "drop_name", label: "Drop-off Customer", type: "text", required: false },
        phone: {
          id: "drop_phone",
          label: "Drop-off Phone",
          type: "tel",
          minLength: 10,
          maxLength: 10,
          required: false,
        },
        address: { value: "36.94696° N, 112.95568° W", type: "hidden" },
        city: cites,
        state: drop.state,
        zip: drop.zip,
      },
    },
    // "+1 (928) 875-2299": {
    //   label: "Business name",
    //   name: "Blue Agave Mexican Kitchen",
    //   pickup: {
    //     address: "390 Arizona Ave",
    //     city: cites[3],
    //     state: "AZ",
    //     zip: 86021,
    //   },
    //   drop: {
    //     name: { id: "drop_name", label: "Drop-off Customer", type: "text", required: false },
    //     phone: {
    //       id: "drop_phone",
    //       label: "Drop-off Phone",
    //       type: "tel",
    //       minLength: 10,
    //       maxLength: 10,
    //       required: false,
    //     },
    //     address: { value: "36.94696° N, 112.95568° W", type: "hidden" },
    //     city: cites,
    //     state: drop.state,
    //     zip: drop.zip,
    //   },
    // },
  },
  ids: ["+1 (435) 429-3899", "+1 (435) 215-2980", "+1 (928) 875-2299"],
};

const formFields: any = {
  companies: company.fields,
  name: { label: "Customer name", type: "text", required: true },
  phone: {
    label: "Phone number",
    type: "tel",
    companyIds: company.ids,
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  email: { label: "Email", type: "text", required: false },
  pickup: pickup,
  drop: drop,
  message: {
    label: "Special instructions",
    type: "text-field",
    required: false,
    maxLength: 250,
  },
};

function createData(location: string, price: number) {
  return { location, price };
}

const rows = [
  createData("Blue Agave", 9),
  createData("Local", 20),
  createData("Hurricane (UT)", 50),
  createData("St George (UT)", 100),
];

export default function App() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      background: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${backgroundImage})`,
      },
      foreground: {
        minWidth: "100%",
        minHeight: "100%",
      },
      locations: {
        minWidth: "32%",
        minHeight: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      table: {
        maxWidth: 725,
      },
      rules: {
        textAlign: "center",
        maxWidth: "715px",
        fontFamily: "Georgia, serif",
      },
      form: {
        color: "#ffffff",
      },
    })
  );
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.background}>
        <section className={classes.foreground}>
          <Wrapper>
            <Header text={environment.siteTitle} style={bannerText.style} divider={true} />
          </Wrapper>
          <Spacer height={32} />
          <Wrapper>
            <Header text="Service Locations" style={headerText.style} />
            <Card className={classes.locations}>
              <CardMedia
                component="img"
                image={locations}
                alt="Utah/Arizona border map"
                title="Service Map"
                height="425"
              />
            </Card>
          </Wrapper>
          <Spacer height={32} />
          <Wrapper>
            <Header text="Location Pricing" style={headerText.style} />
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Service Location</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.location}>
                      <TableCell component="th" scope="row">
                        {row.location}
                      </TableCell>
                      <TableCell align="right">{"$" + row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Wrapper>
          <Spacer height={32} />
          <Wrapper>
            <Header text="Rules & Restrictions" style={headerText.style} />
            <Card>
              <Typography className={classes.rules}>{rules}</Typography>
            </Card>
          </Wrapper>
          <Spacer height={32} />
          <Form className={classes.form} formFields={formFields} urlRequest="/public/delivery/request" />
          <Spacer height={32} />
          <Wrapper>
            <CallIcon />
            <Card>
              <Typography>
                <a href="tel:+19282180352">(928) 218-0352</a>
              </Typography>
            </Card>
          </Wrapper>
        </section>
      </div>
    </ThemeProvider>
  );
}
